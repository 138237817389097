
/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import '~font-awesome/css/font-awesome.css';
@import '~@angular/cdk/overlay-prebuilt.css';

.mat-select-panel {
    max-width: 500px !important;
}

.mat-autocomplete-panel {
    /*max-width: 500px !important;*/
}

table {
    width: 100%;
}

body{
    font-family: Tahoma, 'Segoe UI', Geneva, Verdana, sans-serif !important
}

th
{
    padding: 2px 8px !important;
    text-align: center !important;
    color: white !important;
    background-color: #3498DB !important;
    font-size: 12px !important;
}

th.mat-sort-header-sorted {
    color: black;
}

.appname {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    top: 21px;
}

.appversion {
    font-size: 10px;
}

.loggedappname {
    font-weight: bold;
    font-size: 14px;
    position: relative;
    top: 0px;
}

.loggedappversion {
    font-size: 10px;
}

mat-header-cell {
    display:flex;
    justify-content:flex-end;
}

td.mat-cell {
    border: 1px silver solid;
    padding: 5px !important;
    font-size: 11px;
}

td.mat-footer-cell, th.mat-header-cell {
    border: 1px silver solid;
    padding: 0px;
    font-size: 11px;
}

.mat-header-cell, .mat-footer-cell {
    color: black;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
}

.menuicon {
    width: 30px;
    padding-right:5px;
}

.container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.container {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.container.no-gutters > [class^="col-"],
.container.no-gutters > [class*=" col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 3 !important;
    padding-left: 3 !important;
}

.menuitem{
    border: 2px Solid silver !important;
    margin: 2px;
    border-radius:3px;
    -moz-border-radius:3px;
    -webkit-border-radius:3px;
}
.menuitem:hover{
    border: 2px Solid gray !important;
}

.menuitem > navlink:hover{
    border: 0px Solid gray !important;
}

.align-left
{
    text-align: left
}

.align-right
{
    text-align: right
}

.align-center
{
    text-align: center
}



.nowrap {
    white-space: nowrap;
}

textarea {
    resize: none;
}

.form-control:disabled, .form-control[readonly] {
    background-color: transparent !important;
}

.cross {
    position: relative;
    display: inline-block;
}
.cross::before, .cross::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
}
.cross::before {
    border-bottom: 1px solid black;
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
}
.cross::after {
    border-bottom: 1px solid black;
    -webkit-transform: skewY(10deg);
    transform: skewY(10deg);
}

.page-title {
    text-align: center;
    font-size: 20px;
    color: black;
    padding: 1px;
    margin: 5px;
}

.page-footer {
    text-align: center;
    font-size: 20px;
    background-color: #3498DB;
    color: white;
    padding: 1px;
    margin: 5px;
    min-height: 20px;
}

tr.mat-header-row  {
    height: 36px !important;
}

.label-down {
    padding-top: 15px !important;
}

.footer {
    z-index: 2;
 }

 .right-text {
    text-align:right !important;
 }

 .header1 {
    background: #3498DB;
    padding: 7px;
    color: white
 }

 .header2 {
    border-radius: 8px;
    background: cadetblue;
    padding: 5px;
    color: white
}

.footer1 {
    background: #3498DB;
    padding: 7px;
    color: white
 }

.actiondiv .btn
{
    padding: 0px 5px;
    font-size: 12px;
}

.actiondiv img
{
    margin-right: 0px 5px !important;
}
.activediv .dropdown-item
{
    padding: 0px 5px !important;
}

.mat-dialog-container
{
    border:2px #236592 solid;
    padding: 0px !important;
}

.mat-dialog-content
{
    margin: 0px !important;
}
.mat-dialog-actions {
    margin-bottom: 0px !important;
}

.dataChanged {
    color: orange;
}

.mat-dialog-title {
    background-color: #236592;
    color: white;
    padding: 0px 0px 0px 10px;
    font-size:23px;
    height: 35px;
    line-height: 30px;
    text-transform: capitalize;
}

.mat-tab-label {
    height: 25px !important;
    padding-bottom: 12px !important;
}

.close {
    top:0px;
    right:0px;
    padding: 5px;
}

.closeX {
    float:right;
    margin-right: 10px;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
}

.mat-row:nth-child(even){
    background-color:#C8E4E6;
}

.mat-row:nth-child(odd){
    background-color:white;
}

.nonMaterial
{
    margin-top: 10px;
}

.snackbar
{
    color: white;
    background-color: #236592;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    
}

.table-responsive .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
}

.mat-header-row {
    background-color: #3498DB !important;
}

.table-responsive .mat-row,
.table-responsive .mat-header-row {
    display: table-row;
}
.table-responsive .mat-cell,
.table-responsive .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 5px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
}

.cdk-overlay-pane
{
    background-color: white;
}

.datepickerinput .mat-form-field-wrapper
{
    width: 120px;
}

.reportTitle{
    font-weight: bold;
    font-size: 20px;
}

.nonFormField {
    padding-top: 16px;
}

.background
{
    background-image: url(assets/loginbg.jpg);
    background-size: auto;
    background-repeat: no-repeat;
    width: 100%;
    /*height: 545px;*/
}

.footerFixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 99%;
    color: white;
    text-align: center;
    background-color: #236592;
    margin-left: 5px;
}

.notloggedlang
{
    background-color: white;
}
.notloggedlang a {
    color: #236592 !important;
}

.loggedlang
{
    background-color: #236592;
}
.loggedlang a {
    color: white !important;
}

.rotateimage {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.mat-tab-label .mat-tab-label-content{
    color: blue;
    font-weight: bold;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.pdfViewer {
    border: 1px lightgrey solid;
}

.bordered {
    border: 1px black solid;
}

.gridHeader{
    background: #085B83;
    padding: 7px;
    color: white
}